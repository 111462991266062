import React from 'react'

function Homeassistant() {
  return (
    <div>
     <iframe className='w-[95vw] min-h-screen' title="test" src='https://lifesync.us' />
    </div>
  )
}

export default Homeassistant