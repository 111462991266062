
function GoogleFit() {





     return (
     <div>
          <h1>Google</h1>
     </div>
     );
}

export default GoogleFit;