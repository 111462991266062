import React from "react";
import Netlify from "../components/API/Netlify";

function Webtify() {
  return (
    <div>
      <h1>Tesstgst</h1>
      <Netlify />
    </div>
  );
}

export default Webtify;
